<!-- eslint-disable vue/no-mutating-props -->
<template>
  <b-card>
    <div class="d-flex">
      <feather-icon v-if="icon" :icon="icon" size="19" />
      <h4 v-if="title" class="mb-0 ml-50">
        {{ title }}
      </h4>
    </div>
    <b-row v-if="inited">
      <b-col
        v-for="(formField, index) in formFields"
        :key="formField.name ? formField.name : formField.id"
        cols="12"
        :md="formField.colSize ? formField.colSize : 4"
        :class="formField.hidden ? 'hidden' : 'mt-1'"
      >
        <MapWrapper
          v-if="formField.type === 'map'"
          :lat.sync="entity.lat"
          :lng.sync="entity.lng"
          :entity="formField.parent ? entity[formField.parent] : entity"
        />
        <FormField
          v-else-if="!formField.hidden"
          :form-field="formField"
          :module="module"
          :entity="formField.parent ? entity[formField.parent] : entity"
          :autofocus="allowAutofocus && index == 0"
          :checkbox-click="handleCheckboxClick"
          :image-change-input="handleImageChange"
          :append-to-body="true"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import FormField from '@/layouts/entity/FormField.vue'
import MapWrapper from '@/layouts/entity/MapWrapper.vue'

export default {
  components: {
    FormField,
    MapWrapper,
  },
  props: {
    module: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
      required: false,
    },
    title: {
      type: String,
      default: '',
      required: false,
    },
    formFields: {
      type: Array,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
    allowAutofocus: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      blankEntity: {},
      inited: false,
    }
  },
  mounted() {
    this.initBlankEntity()
    this.inited = true
  },
  methods: {
    initBlankEntity() {
      if (!('id' in this.entity)) {
        this.formFields.forEach(field => {
          let newVal = ''
          if (field.type === 'select' || field.type === 'selectmultiple') {
            newVal = null
          } else if (field.type === 'checkbox') {
            newVal = false
          }
          if (field.parent) {
            if (!(field.parent in this.blankEntity)) {
              this.blankEntity[field.parent] = {}
            }
            this.blankEntity[field.parent][field.id] = newVal
          } else {
            this.blankEntity[field.id] = newVal
          }
        })
        this.$parent.entity = this.blankEntity
      }
    },

    initForm(formFields) {
      formFields.forEach(formField => {
        if (formField.type === 'checkbox') {
          this.handleCheckboxClick(formField.id)
        }
      })
    },

    handleCheckboxClick(formFieldId) {
      if (!this.entity[formFieldId]) {
        this.formFields.forEach(formField => {
          if (formField.dependency === formFieldId) {
            // eslint-disable-next-line vue/no-mutating-props
            this.entity[formField.id] = null
          }
        })
      }
    },

    handleImageChange(input, formField) {
      const file = input && input.files && input.files[0]
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          if (formField.parent) {
            // eslint-disable-next-line vue/no-mutating-props
            this.entity[formField.parent][formField.id] = reader.result
          } else {
            // eslint-disable-next-line vue/no-mutating-props
            this.entity[formField.id] = reader.result
          }

          this.rerenderComponent()
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      } else {
        if (formField.parent) {
          // eslint-disable-next-line vue/no-mutating-props
          this.entity[formField.parent][formField.id] = ''
        } else {
          // eslint-disable-next-line vue/no-mutating-props
          this.entity[formField.id] = ''
        }

        this.rerenderComponent()
      }
    },

    rerenderComponent() {
      // Due to improper reactivity we need to redraw the component
      // to display the changes
      this.inited = false
      this.$nextTick(() => {
        this.inited = true
      })
    },
  },
}
</script>
